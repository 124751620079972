.content {
  height: 800px;
  text-align: center;
  background-image: url("001.bfe70e95.webp");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: cover;
  -ms-flex-pack: center;
  -ms-flex-align: center;
  margin-top: 150px;
  display: -ms-flexbox;
  position: relative;
}

.content__mask {
  width: 100%;
  height: 100%;
  background: linear-gradient(#0006 0, #00000080 100%);
  position: absolute;
}

.content__text {
  color: #fff;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.content__title {
  letter-spacing: -2px;
  font-size: 60px;
  font-weight: 700;
  transition: none;
}

.content__sub-title {
  color: #fff;
  visibility: inherit;
  white-space: normal;
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 400;
  transition: none;
}

@media (max-width: 991px) {
  .content {
    margin-top: 46px;
  }
}

@media (max-width: 470px) {
  .content {
    height: 350px;
  }

  .content__title {
    font-size: 30px;
  }

  .content__sub-title {
    font-size: 20px;
  }
}

@media (min-width: 471px) and (max-width: 575.98px) {
  .content {
    height: 450px;
  }

  .content__title {
    font-size: 40px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .content {
    height: 600px;
  }

  .content__title {
    font-size: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .content {
    height: 650px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .content {
    height: 800px;
  }
}

@media (min-width: 1200px) {
  .content {
    height: 800px;
  }
}

/*# sourceMappingURL=content.d007985f.css.map */
