.content {
  margin-top: 150px;
  position: relative;
  background-image: url("../img/001.webp");
  background-position: 50% 0;
  height: 800px;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.content__mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, .5)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, .4) 0, rgba(0, 0, 0, .5) 100%);
}

.content__text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100%;
}

.content__title {
  transition: none 0s ease 0s;
  letter-spacing: -2px;
  font-weight: 700;
  font-size: 60px;
}

.content__sub-title {
  color: white;
  visibility: inherit;
  transition: none 0s ease 0s;
  font-size: 24px;
  white-space: normal;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

@media (max-width: 991px) {
  .content {
    margin-top: 46px;
  }
}

@media (max-width: 470px) {
  .content {
    height: 350px;
  }

  .content__title {
    font-size: 30px;
  }

  .content__sub-title {
    font-size: 20px;
  }
}

@media (min-width: 471px) and (max-width: 575.98px) {
  .content {
    height: 450px;
  }

  .content__title {
    font-size: 40px;
  }
}


@media (min-width: 576px) and (max-width: 767.98px) {
  .content {
    height: 600px;
  }

  .content__title {
    font-size: 40px;
  }
}


@media (min-width: 768px) and (max-width: 991.98px) {
  .content {
    height: 650px;
  }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
  .content {
    height: 800px;
  }
}

@media (min-width: 1200px) {
  .content {
    height: 800px;
  }
}
